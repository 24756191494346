import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backgroundImage from '../assets/images/background.png';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './Home.css';

const Help = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [tooltipType, setTooltipType] = useState(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    actions: false,
    conditions: false,
    damageTypes: false,
    gameMechanics: false,
    movement: false,
    skills: false,
    resting: false,
    objects: false,
    turn: false
  });

  // The sequence of pages for navigation
  const pageSequence = [
    '/',
    '/history',
    '/house-rules',
    '/prologue',
    '/veiled-ascension',
    '/players-guide'
  ];

  // Required minimum distance traveled to be considered a swipe
  const minSwipeDistance = 50;

  // Handle navigation based on swipe direction
  const handleSwipe = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // Get current page index in the sequence
    const currentIndex = pageSequence.findIndex(page => page === location.pathname);
    
    // Navigate based on swipe direction
    if (isLeftSwipe) {
      // Navigate to next page (or first if at end)
      const nextIndex = (currentIndex + 1) % pageSequence.length;
      navigate(pageSequence[nextIndex]);
    } else if (isRightSwipe) {
      // Navigate to previous page (or last if at first)
      const prevIndex = (currentIndex - 1 + pageSequence.length) % pageSequence.length;
      navigate(pageSequence[prevIndex]);
    }
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
  };

  // Mouse event handlers for desktop testing
  const onMouseDown = (e) => {
    setTouchEnd(null);
    setTouchStart(e.clientX);
  };

  const onMouseMove = (e) => {
    if (!touchStart) return;
    setTouchEnd(e.clientX);
  };

  const onMouseUp = () => {
    if (!touchStart || !touchEnd) return;
    handleSwipe();
    setTouchStart(null);
    setTouchEnd(null);
  };

  // Clean up event listeners
  useEffect(() => {
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const actionTooltips = {
    attack: "When you take the Attack action, you can make one attack roll with a weapon or an Unarmed Strike.\n\nEquipping and Unequipping Weapons: You can either equip or unequip one weapon when you make an attack as part of this action. You do so either before or after the attack. If you equip a weapon before an attack, you don't need to use it for that attack.\n\nMoving Between Attacks: If you move on your turn and have a feature, such as Extra Attack, that gives you more than one attack as part of the Attack action, you can use some or all of that movement to move between those attacks.",
    help: "When you take the Help action, you do one of the following:\n\nAssist an Ability Check: Choose one of your skill or tool proficiencies and one ally who is near enough for you to assist verbally or physically when they make an ability check. That ally has Advantage on the next ability check they make with the chosen skill or tool.\n\nAssist an Attack Roll: You momentarily distract an enemy within 5 feet of you, giving Advantage to the next attack roll by one of your allies against that enemy.",
    influence: "With the Influence action, you urge a monster to do something. Describe or roleplay how you're communicating with the monster. The DM determines whether the monster feels willing, unwilling, or hesitant due to your interaction. Hesitant interactions require ability checks based on the monster's attitude and the approach used.",
    search: "When you take the Search action, you make a Wisdom check to discern something that isn't obvious. Skills like Insight, Medicine, Perception, or Survival may apply, depending on what you're trying to detect.",
    dodge: "If you take the Dodge action, you gain the following benefits: until the start of your next turn, any attack roll made against you has Disadvantage if you can see the attacker, and you make Dexterity saving throws with Advantage. You lose these benefits if you're incapacitated or if your speed is 0.",
    disengage: "If you take the Disengage action, your movement doesn't provoke Opportunity Attacks for the rest of the current turn.",
    magic: "When you take the Magic action, you cast a spell with a casting time of an action or use a feature or magic item requiring a Magic action. Spells with casting times of 1 minute or longer require continued Magic actions and Concentration.",
    study: "When you take the Study action, you make an Intelligence check to study your memory, a book, a clue, or another source of knowledge. Skills like Arcana, History, Investigation, Nature, or Religion may apply depending on the subject of study.",
    dash: "When you take the Dash action, you gain extra movement for the current turn. The increase equals your Speed after applying any modifiers.",
    hide: "With the Hide action, you try to conceal yourself. To do so, you must succeed on a DC 15 Dexterity (Stealth) check while you're Heavily Obscured or behind sufficient cover and out of enemy line of sight.",
    ready: "You take the Ready action to wait for a particular circumstance before you act. You decide a trigger and an action to take in response to it, using your Reaction when the trigger occurs.",
    utilize: "When an object requires an action for its use, you take the Utilize action. This includes using objects like potions, traps, or other special items."
  };

  const conditionTooltips = {
    blinded: "While you have the Blinded condition, you experience the following effects.\n\nCan't See. You can't see and automatically fail any ability check that requires sight.\n\nAttacks Affected. Attack rolls against you have Advantage, and your attack rolls have Disadvantage.",
    bloodied: "A creature is Bloodied while it has half its Hit Points or fewer remaining.",
    charmed: "While you have the Charmed condition, you experience the following effects.\n\nCan't Harm the Charmer. You can't attack the charmer or target the charmer with damaging abilities or magical effects.\n\nSocial Advantage. The charmer has Advantage on any ability check to interact with you socially.",
    concentration: "Some spells and other effects require Concentration to remain active, as specified in their descriptions. If the effect's creator loses Concentration, the effect ends. If the effect has a maximum duration, the effect's description specifies how long the creator can concentrate on it: up to 1 minute, 1 hour, or some other duration. The creator can end Concentration at any time (no action required). The following factors break Concentration.\n\nAnother Concentration Effect. You lose Concentration on an effect the moment you start casting a spell that requires Concentration or activate another effect that requires Concentration.\n\nDamage. If you take damage, you must succeed on a Constitution saving throw to maintain Concentration. The DC equals 10 or half the damage taken (round down), whichever number is higher, up to a maximum DC of 30.\n\nIncapacitated or Dead. Your Concentration ends if you have the Incapacitated condition or you die.",
    dead: "A dead creature has no Hit Points and can't regain them unless it is first revived by magic such as the Raise Dead or Revivify spell. When such a spell is cast, the spirit knows who is casting it and can refuse. The spirit of a dead creature has left the body and departed for the Outer Planes, and reviving the creature requires calling the spirit back.\n\nIf the creature returns to life, the revival effect determines the creature's current Hit Points. Unless otherwise stated, the creature returns to life with any conditions, magical contagions, or curses that were affecting it at death if the durations of those effects are still ongoing. If the creature died with any Exhaustion levels, it returns with 1 fewer level. If the creature had Attunement to one or more magic items, it is no longer attuned to them.",
    deafened: "While you have the Deafened condition, you experience the following effect.\n\nCan't Hear. You can't hear and automatically fail any ability check that requires hearing.",
    exhaustion: "While you have the Exhaustion condition, you experience the following effects.\n\nExhaustion Levels. This condition is cumulative. Each time you receive it, you gain 1 Exhaustion level. You die if your Exhaustion level is 6.\n\nD20 Tests Affected. When you make a D20 Test the roll is reduced by 2 times your Exhaustion level.\n\nSpeed Reduced. Your Speed is reduced by a number of feet equal to 5 times your Exhaustion level.\n\nRemoving Exhaustion Levels. Finishing a Long Rest removes 1 of your Exhaustion levels. When your Exhaustion level reaches 0, the condition ends.",
    frightened: "While you have the Frightened condition, you experience the following effects.\n\nAbility Checks and Attacks Affected. You have Disadvantage on ability checks and attack rolls while the source of fear is within line of sight.\n\nCan't Approach. You can't willingly move closer to the source of fear.",
    grappled: "While you have the Grappled condition, you experience the following effects.\n\nSpeed 0. Your Speed is 0 and can't increase.\n\nAttacks Affected. You have Disadvantage on attack rolls against any target other than the grappler.\n\nMovable. The grappler can drag or carry you when it moves, but every foot of movement costs it 1 extra foot unless you are Tiny or two or more sizes smaller than it.",
    immunity: "If you have Immunity to a damage type or a condition, it doesn't affect you in any way.",
    incapacitated: "While you have the Incapacitated condition, you experience the following effects.\n\nInactive. You can't take any action, Bonus Action, or Reaction.\n\nNo Concentration. Your Concentration is broken.\n\nSpeechless. You can't speak.\n\nSurprised. If you're Incapacitated when you roll Initiative, you have Disadvantage on the roll.",
    invisible: "While you have the Invisible condition, you experience the following effects.\n\nSurprise. If you're Invisible when you roll Initiative, you have Advantage on the roll.\n\nConcealed. You aren't affected by any effect that requires its target to be seen unless the effect's creator can somehow see you. Any equipment you are wearing or carrying is also concealed.\n\nAttacks Affected. Attack rolls against you have Disadvantage, and your attack rolls have Advantage. If a creature can somehow see you, you don't gain this benefit against that creature.",
    paralyzed: "While you have the Paralyzed condition, you experience the following effects.\n\nIncapacitated. You have the Incapacitated condition.\n\nSpeed 0. Your Speed is 0 and can't increase.\n\nSaving Throws Affected. You automatically fail Strength and Dexterity saving throws.\n\nAttacks Affected. Attack rolls against you have Advantage.\n\nAutomatic Critical Hits. Any attack roll that hits you is a Critical Hit if the attacker is within 5 feet of you.",
    petrified: "While you have the Petrified condition, you experience the following effects.\n\nTurned to Inanimate Substance. You are transformed, along with any nonmagical objects you are wearing and carrying, into a solid inanimate substance (usually stone). Your weight increases by a factor of ten, and you cease aging.\n\nIncapacitated. You have the Incapacitated condition.\n\nSpeed 0. Your Speed is 0 and can't increase.\n\nAttacks Affected. Attack rolls against you have Advantage.\n\nSaving Throws Affected. You automatically fail Strength and Dexterity saving throws.\n\nResist Damage. You have Resistance to all damage.\n\nPoison Immunity. You have Immunity to the Poisoned condition.",
    poisoned: "While you have the Poisoned condition, you experience the following effect.\n\nAbility Checks and Attacks Affected. You have Disadvantage on attack rolls and ability checks.",
    prone: "While you have the Prone condition, you experience the following effects.\n\nRestricted Movement. Your only movement options are to crawl or to spend an amount of movement equal to half your Speed (round down) to right yourself and thereby end the condition. If your Speed is 0, you can't right yourself.\n\nAttacks Affected. You have Disadvantage on attack rolls. An attack roll against you has Advantage if the attacker is within 5 feet of you. Otherwise, that attack roll has Disadvantage.",
    resistance: "If you have Resistance to a damage type, damage of that type is halved against you (round down). Resistance is applied only once to an instance of damage.",
    restrained: "While you have the Restrained condition, you experience the following effects.\n\nSpeed 0. Your Speed is 0 and can't increase.\n\nAttacks Affected. Attack rolls against you have Advantage, and your attack rolls have Disadvantage.\n\nSaving Throws Affected. You have Disadvantage on Dexterity saving throws.",
    stable: "A creature is Stable if it has 0 Hit Points but isn't required to make Death Saving Throws.",
    stunned: "While you have the Stunned condition, you experience the following effects.\n\nIncapacitated. You have the Incapacitated condition.\n\nSaving Throws Affected. You automatically fail Strength and Dexterity saving throws.\n\nAttacks Affected. Attack rolls against you have Advantage.",
    "temporary hit points": "Temporary Hit Points are granted by certain effects and act as a buffer against losing real Hit Points.",
    unconscious: "While you have the Unconscious condition, you experience the following effects.\n\nInert. You have the Incapacitated and Prone conditions, and you drop whatever you're holding. When this condition ends, you remain Prone.\n\nSpeed 0. Your Speed is 0 and can't increase.\n\nAttacks Affected. Attack rolls against you have Advantage.\n\nSaving Throws Affected. You automatically fail Strength and Dexterity saving throws.\n\nAutomatic Critical Hits. Any attack roll that hits you is a Critical Hit if the attacker is within 5 feet of you.\n\nUnaware. You're unaware of your surroundings.",
    vulnerability: "If you have Vulnerability to a damage type, damage of that type is doubled against you. Vulnerability is applied only once to an instance of damage."
  };

  const damageTypes = {
    acid: "Corrosive liquids, digestive enzymes",
    bludgeoning: "Blunt objects, constriction, falling",
    cold: "Freezing water, icy blasts",
    fire: "Flames, unbearable heat",
    force: "Pure magical energy",
    lightning: "Electricity",
    necrotic: "Life-draining energy",
    piercing: "Fangs, puncturing objects",
    poison: "Toxic gas, venom",
    psychic: "Mind-rending energy",
    radiant: "Holy energy, searing radiation",
    slashing: "Claws, cutting objects",
    thunder: "Concussive sound"
  };

  const gameMechanicsInfo = {
    advantage: "If you have Advantage on a D20 Test, roll two d20s, and use the higher roll. A roll can't be affected by more than one Advantage, and Advantage and Disadvantage on the same roll cancel each other.",
    disadvantage: "If you have Disadvantage on a D20 Test, roll two d20s and use the lower roll. A roll can't be affected by more than one Disadvantage, and Advantage and Disadvantage on the same roll cancel each other.",
    "passive perception": "Passive Perception is a score that reflects a creature's general awareness of its surroundings. The DM uses this score when determining whether a creature notices something without consciously making a Wisdom (Perception) check.\n\nA creature's Passive Perception equals 10 plus the creature's Wisdom (Perception) check bonus. If the creature has Advantage on such checks, increase the score by 5. If the creature has Disadvantage on them, decrease the score by 5. For example, a level 1 character with a Wisdom of 15 and proficiency in Perception has a Passive Perception of 14 (10 + 2 + 2). If that character has Advantage on Wisdom (Perception) checks, the score becomes 19.",
    attunement: "Some magic items require a creature to form a bond—called Attunement—with them before the creature can use an item's magical properties. A creature can have Attunement with no more than three magic items at a time.",
    "opportunity attack": "You can make an Opportunity Attack when a creature that you can see leaves your reach using its action, its Bonus Action, its Reaction, or one of its speeds. To make the Opportunity Attack, take a Reaction to make one melee attack with a weapon or an Unarmed Strike against the provoking creature. The attack occurs right before the creature leaves your reach."
  };

  const movementInfo = {
    speed: "A creature has a Speed, which is the distance in feet the creature can cover when it moves on its turn.\n\nSpecial Speeds. Some creatures have special speeds, such as a Burrow Speed, Climb Speed, Fly Speed, or Swim Speed, each of which is defined in this glossary. If you have more than one speed, choose which one to use when you move; you can switch between the speeds during your move. Whenever you switch, subtract the distance already moved from the new speed. The result determines how much farther you can move. If the result is 0 or less, you can't use the new speed during the current move. For example, if you have a Speed of 30 and a Fly Speed of 40, you could fly 10 feet, walk 10 feet, and leap into the air to fly 20 feet more.\n\nChanges to Your Speeds. If an effect increases or decreases your Speed for a time, any special speed you have increases or decreases by an equal amount for the same duration. For example, if your Speed is reduced to 0 and you have a Climb Speed, your Climb Speed is also reduced to 0. Similarly, if your Speed is halved and you have a Fly Speed, your Fly Speed is also halved.",
    "difficult terrain": "If a space is Difficult Terrain, every foot of movement in that space costs 1 extra foot. For example, moving 5 feet through Difficult Terrain costs 10 feet of movement. Difficult Terrain isn't cumulative; either a space is Difficult Terrain or it isn't.\n\nA space is Difficult Terrain if the space contains any of the following or something similar:\n\nA creature that isn't Tiny or your ally\nFurniture that is sized for creatures of your size or larger\nHeavy snow, ice, rubble, or undergrowth\nLiquid that's between shin- and waist-deep\nA narrow opening sized for a creature one size smaller than you\nA slope of 20 degrees or more",
    "carrying capacity": "Your size and Strength score determines the maximum weight in pounds that you can carry, as shown in the Carrying Capacity table. The table also shows the maximum weight you can drag, lift or push.\n\nWhile dragging, lifting, or pushing weight in excess of the maximum weight you can carry, your Speed can be no more than 5 feet.\n\nCarrying Capacity\nCreature Size\tCarry\tDrag/Lift/Push\nTiny\tStr. × 7.5 lb.\tStr. × 15 lb.\nSmall/Medium\tStr. × 15 lb.\tStr. × 30 lb.\nLarge\tStr. × 30 lb.\tStr. × 60 lb.\nHuge\tStr. × 60 lb.\tStr. × 120 lb.\nGargantuan\tStr. × 120 lb.\tStr. × 240 lb."
  };

  const restingInfo = {
    "long rest": "A Long Rest is a period of extended downtime—at least 8 hours—available to any creature. During a Long Rest, you sleep for at least 6 hours and perform no more than 2 hours of light activity, such as reading, talking, eating, or standing watch.\n\nDuring sleep, you have the Unconscious condition. After you finish a Long Rest, you must wait at least 16 hours before starting another one.\n\nBenefits of the Rest. To start a Long Rest, you must have at least 1 Hit Point. When you finish the rest, you gain the following benefits:\n\nRegain All HP. You regain all lost Hit Points and all spent Hit Point Dice. If your Hit Point maximum was reduced, it returns to normal.\n\nAbility Scores Restored. If any of your ability scores were reduced, they return to normal.\n\nExhaustion Reduced. If you have the Exhaustion condition, its level decreases by 1.\n\nSpecial Feature. Some features are recharged by a Long Rest. If you have such a feature, it recharges in the way specified in its description.\n\nInterrupting the Rest. A Long Rest is stopped by the following interruptions:\n\nRolling Initiative\nCasting a spell other than a cantrip\nTaking any damage\n1 hour of walking or other physical exertion.\nIf you rested at least 1 hour before the interruption you gain the benefits of a Short Rest.\n\nYou can resume a Long Rest immediately after an interruption. If you do so, the rest requires 1 additional hour per interruption to finish.",
    "short rest": "A Short Rest is a 1-hour period of downtime, during which a creature does nothing more strenuous than reading, talking, eating, or standing watch. To start a Short Rest, you must have at least 1 Hit Point.\n\nBenefits of the Rest. When you finish the rest, you gain the following benefits:\n\nSpend Hit Point Dice. You can spend one or more of your Hit Point Dice to regain Hit Points. For each Hit Point Die you spend in this way, roll the die and add your Constitution modifier to it. You regain Hit Points equal to the total (minimum of 1 Hit Point). You can decide to spend an additional Hit Point Die after each roll.\n\nSpecial Feature. Some features are recharged by a Short Rest. If you have such a feature, it recharges in the way specified in its description.\n\nInterrupting the Rest. A Short Rest is stopped by the following interruptions:\n\nRolling Initiative\nCasting a spell other than a cantrip\nTaking any damage\nAn interrupted Short Rest confers no benefits."
  };

  const objectsInfo = {
    "breaking objects": "Objects can be harmed by attacks and by some spells, using the rules below. If an object is exceedingly fragile, the DM may allow a creature to break it automatically with the Attack or Utilize action.\n\nArmor Class. The Object Armor Class table suggests ACs for various substances.\n\nObject Armor Class\nAC\tSubstance\n11\tCloth, paper, rope\n13\tCrystal, glass, ice\n15\tWood\n17\tStone\n19\tIron, steel\n21\tMithral\n23\tAdamantite\n\nHit Points. An object is destroyed when it has 0 Hit Points. The Object Hit Points table suggests Hit Points for fragile and resilient objects that are Large or smaller. To track Hit Points for a Huge or Gargantuan object, divide it into Large or smaller sections, and track each section's Hit Points separately. The DM determines whether destroying part of an object causes the whole thing to collapse.\n\nObject Hit Points\nSize\tFragile\tResilient\nTiny (bottle, lock)\t2 (1d4)\t5 (2d4)\nSmall (chest, lute)\t3 (1d6)\t10 (3d6)\nMedium (barrel, chandelier)\t4 (1d8)\t18 (4d8)\nLarge (cart, dining table)\t5 (1d10)\t27 (5d10)\n\nDamage Types and Objects. Objects have Immunity to Poison and Psychic damage. The DM might decide that some damage types are more or less effective against an object. For example, Bludgeoning damage works well for smashing things but not for cutting. Paper or cloth objects might have Vulnerability to Fire damage.\n\nDamage Threshold. Big objects, such as castle walls, often have extra resilience represented by a damage threshold.\n\nNo Ability Scores. An object lacks ability scores unless a rule assigns scores to the object. Without ability scores, an object can't make ability checks, and it fails all saving throws."
  };

  const skillList = {
    skills: `Skill\tAbility\tExample Uses
Acrobatics\tDexterity\tStay on your feet in a tricky situation, or perform an acrobatic stunt.
Animal Handling\tWisdom\tCalm or train an animal, or get an animal to behave in a certain way.
Arcana\tIntelligence\tRecall lore about spells, magic items, and the planes of existence.
Athletics\tStrength\tJump farther than normal, stay afloat in rough water, or break something.
Deception\tCharisma\tTell a convincing lie, or wear a disguise convincingly.
History\tIntelligence\tRecall lore about historical events, people, nations, and cultures.
Insight\tWisdom\tDiscern a person's mood and intentions.
Intimidation\tCharisma\tAwe or threaten someone into doing what you want.
Investigation\tIntelligence\tFind obscure information in books, or deduce how something works.
Medicine\tWisdom\tDiagnose an illness, or determine what killed the recently slain.
Nature\tIntelligence\tRecall lore about terrain, plants, animals, and weather.
Perception\tWisdom\tUsing a combination of senses, notice something that's easy to miss.
Performance\tCharisma\tAct, tell a story, perform music, or dance.
Persuasion\tCharisma\tHonestly and graciously convince someone of something.
Religion\tIntelligence\tRecall lore about gods, religious rituals, and holy symbols.
Sleight of Hand\tDexterity\tPick a pocket, conceal a handheld object, or perform legerdemain.
Stealth\tDexterity\tEscape notice by moving quietly and hiding behind things.
Survival\tWisdom\tFollow tracks, forage, find a trail, or avoid natural hazards.`
  };

  const handleClick = (item, type) => {
    if (activeTooltip === item && tooltipType === type) {
      setActiveTooltip(null);
      setTooltipType(null);
    } else {
      setActiveTooltip(item);
      setTooltipType(type);
    }
  };

  const getTooltipContent = () => {
    if (!activeTooltip || !tooltipType) return null;
    switch (tooltipType) {
      case 'action':
        return actionTooltips[activeTooltip];
      case 'condition':
        return conditionTooltips[activeTooltip];
      case 'mechanics':
        return gameMechanicsInfo[activeTooltip];
      case 'movement':
        return movementInfo[activeTooltip];
      case 'resting':
        return restingInfo[activeTooltip];
      case 'objects':
        return objectsInfo[activeTooltip];
      case 'skills':
        return skillList[activeTooltip];
      default:
        return null;
    }
  };

  return (
    <div 
      className="mainContainer"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <div 
        className="contentContainer"
        style={{ 
          backgroundImage: `url(${backgroundImage})`,
          minHeight: '100vh',
          width: '100%',
          overflowX: 'hidden'
        }}
      >
        <div className="contentOverlay">
          <div className="sectionContent" style={{ 
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95))',
            padding: '1rem',
            borderRadius: '12px',
            maxWidth: '1200px',
            margin: '0 auto',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            '@media (min-width: 768px)': {
              padding: '2rem'
            }
          }}>
            <h1 style={{ 
              textAlign: 'center', 
              marginBottom: '2rem',
              fontSize: 'clamp(1.8rem, 4vw, 2.5rem)',
              background: 'linear-gradient(45deg, #4a9eff, #6eb6ff)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
            }}>
              First Veil Player's Guide
            </h1>

            <div className="help-sections-container" style={{
              display: 'grid',
              gap: '1.5rem',
              width: '100%'
            }}>
              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('turn')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Your Turn</h2>
                  {expandedSections.turn ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.turn && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>On your turn, you can do the following:</p>
                    
                    <h3 style={{ color: '#4a9eff' }}>Perform an Action</h3>
                    <p>Choose from the actions listed below in the "Actions" section. Examples include:</p>
                    <ul>
                      <li>Attack: Make a melee or ranged attack with a weapon.</li>
                      <li>Magic: Cast a spell with a casting time of one action, such as Fireball or Cure Wounds.</li>
                      <li>Dash: Double your movement for the turn.</li>
                    </ul>

                    <h3 style={{ color: '#4a9eff' }}>Bonus Action</h3>
                    <p>Bonus actions are additional actions allowed by specific abilities, spells, or features. Players can only take one bonus action per turn. Examples:</p>
                    <ul>
                      <li>Two-Weapon Fighting: Attack with a second light melee weapon.</li>
                      <li>Cunning Action (Rogue): Hide, Disengage, or Dash as a bonus action.</li>
                      <li>Spell Casting: Cast certain spells as a bonus action, like Healing Word or Misty Step.</li>
                    </ul>

                    <h3 style={{ color: '#4a9eff' }}>Movement</h3>
                    <p>Players can move up to their speed on their turn. Movement can be split before and after actions. Examples:</p>
                    <ul>
                      <li>Basic Movement: Move up to your speed in any direction.</li>
                      <li>Climbing: Each foot of climbing costs 1 extra foot of movement (2 extra feet in difficult terrain). If you have a Climb Speed, you ignore this extra cost when using it to climb. At the DM's discretion, climbing a slippery surface or one with few handholds might require a successful DC 15 Strength (Athletics) check.</li>
                      <li>Swimming: Each foot of swimming costs 1 extra foot of movement (2 extra feet in difficult terrain). If you have a Swim Speed, you ignore this extra cost when using it to swim. At the DM's discretion, moving any distance in rough water might require a successful DC 15 Strength (Athletics) check.</li>
                    </ul>

                    <h3 style={{ color: '#4a9eff' }}>Interacting with Objects</h3>
                    <p>Players can interact with one object or feature of the environment for free during their turn, such as opening a door or picking up an item.</p>

                    <h3 style={{ color: '#4a9eff' }}>Reactions</h3>
                    <p>Reactions are instant responses to triggers, which can occur on the player's turn or someone else's turn. Players typically have one reaction per round. Examples:</p>
                    <ul>
                      <li>Opportunity Attack: Make a melee attack against an enemy moving out of reach.</li>
                      <li>Counterspell (Spell Reaction): Interrupt a spell being cast.</li>
                      <li>Shield (Spell Reaction): Temporarily increase AC in response to an attack.</li>
                      <li>Deflect Missiles (Monk Feature): Reduce damage from a ranged weapon attack.</li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('actions')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Actions</h2>
                  {expandedSections.actions ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.actions && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>On your turn, you have a variety of options to choose from. Click on an action below to learn more:</p>
                    <div className="action-grid">
                      {Object.keys(actionTooltips).map((action) => (
                        <div key={action} className="action-item">
                          <div
                            onClick={() => handleClick(action, 'action')}
                            className="action-button"
                          >
                            {action.charAt(0).toUpperCase() + action.slice(1)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('conditions')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Conditions</h2>
                  {expandedSections.conditions ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.conditions && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>These conditions can affect a creature during play. Click on a condition to learn more:</p>
                    <div className="action-grid">
                      {Object.keys(conditionTooltips).map((condition) => (
                        <div key={condition} className="action-item">
                          <div
                            onClick={() => handleClick(condition, 'condition')}
                            className="action-button"
                          >
                            {condition.charAt(0).toUpperCase() + condition.slice(1)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('damageTypes')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Damage Types</h2>
                  {expandedSections.damageTypes ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.damageTypes && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Attacks and other harmful effects deal different types of damage. Damage types have no rules of their own, but other rules, such as Resistance, rely on the types. Below are the different types of damage and examples of their sources:</p>
                    <div className="damage-table">
                      <div className="damage-table-header">
                        <div className="damage-type-col">Type</div>
                        <div className="damage-examples-col">Examples</div>
                      </div>
                      {Object.entries(damageTypes).map(([type, examples]) => (
                        <div key={type} className="damage-table-row">
                          <div className="damage-type-col">{type.charAt(0).toUpperCase() + type.slice(1)}</div>
                          <div className="damage-examples-col">{examples}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('gameMechanics')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Game Mechanics</h2>
                  {expandedSections.gameMechanics ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.gameMechanics && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Core game mechanics that every player should understand:</p>
                    <div className="action-grid">
                      {Object.keys(gameMechanicsInfo).map((mechanic) => (
                        <div key={mechanic} className="action-item">
                          <div
                            onClick={() => handleClick(mechanic, 'mechanics')}
                            className="action-button"
                          >
                            {mechanic.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('movement')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Movement & Carrying</h2>
                  {expandedSections.movement ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.movement && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Rules about movement, terrain, and carrying capacity:</p>
                    <div className="action-grid">
                      {Object.keys(movementInfo).map((item) => (
                        <div key={item} className="action-item">
                          <div
                            onClick={() => handleClick(item, 'movement')}
                            className="action-button"
                          >
                            {item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('skills')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Skills</h2>
                  {expandedSections.skills ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.skills && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Complete list of skills and their uses:</p>
                    <div className="damage-table">
                      <div className="damage-table-header">
                        <div className="damage-type-col">Skill</div>
                        <div className="damage-examples-col">Example Uses</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Acrobatics (Dex)</div>
                        <div className="damage-examples-col">Stay on your feet in a tricky situation, or perform an acrobatic stunt.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Animal Handling (Wis)</div>
                        <div className="damage-examples-col">Calm or train an animal, or get an animal to behave in a certain way.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Arcana (Int)</div>
                        <div className="damage-examples-col">Recall lore about spells, magic items, and the planes of existence.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Athletics (Str)</div>
                        <div className="damage-examples-col">Jump farther than normal, stay afloat in rough water, or break something.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Deception (Cha)</div>
                        <div className="damage-examples-col">Tell a convincing lie, or wear a disguise convincingly.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">History (Int)</div>
                        <div className="damage-examples-col">Recall lore about historical events, people, nations, and cultures.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Insight (Wis)</div>
                        <div className="damage-examples-col">Discern a person's mood and intentions.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Intimidation (Cha)</div>
                        <div className="damage-examples-col">Awe or threaten someone into doing what you want.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Investigation (Int)</div>
                        <div className="damage-examples-col">Find obscure information in books, or deduce how something works.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Medicine (Wis)</div>
                        <div className="damage-examples-col">Diagnose an illness, or determine what killed the recently slain.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Nature (Int)</div>
                        <div className="damage-examples-col">Recall lore about terrain, plants, animals, and weather.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Perception (Wis)</div>
                        <div className="damage-examples-col">Using a combination of senses, notice something that's easy to miss.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Performance (Cha)</div>
                        <div className="damage-examples-col">Act, tell a story, perform music, or dance.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Persuasion (Cha)</div>
                        <div className="damage-examples-col">Honestly and graciously convince someone of something.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Religion (Int)</div>
                        <div className="damage-examples-col">Recall lore about gods, religious rituals, and holy symbols.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Sleight of Hand (Dex)</div>
                        <div className="damage-examples-col">Pick a pocket, conceal a handheld object, or perform legerdemain.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Stealth (Dex)</div>
                        <div className="damage-examples-col">Escape notice by moving quietly and hiding behind things.</div>
                      </div>
                      <div className="damage-table-row">
                        <div className="damage-type-col">Survival (Wis)</div>
                        <div className="damage-examples-col">Follow tracks, forage, find a trail, or avoid natural hazards.</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('resting')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Resting</h2>
                  {expandedSections.resting ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.resting && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Rules for short and long rests:</p>
                    <div className="action-grid">
                      {Object.keys(restingInfo).map((item) => (
                        <div key={item} className="action-item">
                          <div
                            onClick={() => handleClick(item, 'resting')}
                            className="action-button"
                          >
                            {item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="collapsible-section" style={{
                background: 'rgba(25, 25, 35, 0.7)',
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.3s ease'
              }}>
                <div className="section-header" onClick={() => toggleSection('objects')} style={{
                  padding: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  background: 'rgba(0, 0, 0, 0.1)',
                  transition: 'background 0.3s ease',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.2)'
                  }
                }}>
                  <h2 style={{ 
                    color: '#4a9eff',
                    margin: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    textAlign: 'left'
                  }}>Objects</h2>
                  {expandedSections.objects ? 
                    <ChevronUp size={24} color="#4a9eff" /> : 
                    <ChevronDown size={24} color="#4a9eff" />
                  }
                </div>
                {expandedSections.objects && (
                  <div className="section-content" style={{ textAlign: 'left' }}>
                    <p>Rules for interacting with objects:</p>
                    <div className="action-grid">
                      {Object.keys(objectsInfo).map((item) => (
                        <div key={item} className="action-item">
                          <div
                            onClick={() => handleClick(item, 'objects')}
                            className="action-button"
                          >
                            {item.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {activeTooltip && (
              <div className="tooltip-overlay" onClick={() => handleClick(activeTooltip, tooltipType)} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(4px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
                zIndex: 1000
              }}>
                <div className="tooltip-content" onClick={(e) => e.stopPropagation()} style={{
                  background: 'linear-gradient(to bottom, #1a1a2e, #16213e)',
                  borderRadius: '12px',
                  padding: '1.5rem',
                  maxWidth: '90vw',
                  maxHeight: '85vh',
                  overflow: 'auto',
                  position: 'relative',
                  border: '1px solid rgba(0, 0, 0, 0.2)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)'
                }}>
                  <button className="tooltip-close" onClick={() => handleClick(activeTooltip, tooltipType)} style={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    background: 'none',
                    border: 'none',
                    color: '#4a9eff',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                    padding: '0.5rem',
                    borderRadius: '50%',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.1)'
                    }
                  }}>
                    ×
                  </button>
                  <h3 className="tooltip-title" style={{
                    color: '#4a9eff',
                    marginTop: 0,
                    fontSize: 'clamp(1.2rem, 3vw, 1.5rem)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
                    paddingBottom: '0.5rem'
                  }}>
                    {activeTooltip.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </h3>
                  <div style={{
                    color: '#e0e0e0',
                    lineHeight: 1.6,
                    fontSize: 'clamp(0.9rem, 2vw, 1rem)'
                  }}>
                    {getTooltipContent()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;