import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import LoginBackground from './LoginBackground';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [useThreeJs, setUseThreeJs] = useState(true);
  const [isSignup, setIsSignup] = useState(false);
  const { login, signup } = useAuth();

  // Check if device can handle Three.js
  useEffect(() => {
    // Detect if device is low-powered or has WebGL issues
    const checkWebGLSupport = () => {
      try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || 
                  canvas.getContext('experimental-webgl');
        
        // Check if WebGL is available
        if (!gl) {
          return false;
        }
        
        // Check if device is likely too low-powered
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const isLowPower = isMobile && (
          navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 2 ||
          window.devicePixelRatio < 1.5
        );
        
        return !isLowPower;
      } catch (e) {
        console.log('WebGL detection error:', e);
        return false;
      }
    };
    
    setUseThreeJs(checkWebGLSupport());
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    try {
      setError('');
      setLoading(true);
      await login(email, password);
      // No navigation here - we'll handle that in the AuthContext
    } catch (err) {
      setError('The arcane sigils you provided are incorrect. Try again, adventurer.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    
    // Validate form
    if (password !== confirmPassword) {
      setError('The mystical keys do not match. Please try again.');
      return;
    }
    
    if (password.length < 6) {
      setError('Your mystical key must be at least 6 characters long.');
      return;
    }
    
    if (!displayName.trim()) {
      setError('Please provide a name for your character.');
      return;
    }
    
    try {
      setError('');
      setLoading(true);
      await signup(displayName, email, password);
      // No navigation here - we'll handle that in the AuthContext
    } catch (err) {
      setError(err.message || 'Failed to forge your destiny. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toggleForm = () => {
    setIsSignup(!isSignup);
    setError('');
  };

  // Rune symbols for decorative elements
  const runeSymbols = {
    topLeft: '᛭',
    topRight: 'ᚦ',
    bottomLeft: 'ᚨ',
    bottomRight: 'ᛟ'
  };
  
  // Fallback background runes
  const fallbackRunes = ['ᚠ', 'ᚢ', 'ᚦ', 'ᚨ', 'ᚱ', 'ᚲ', 'ᚷ', 'ᚹ', 'ᚺ', 'ᚾ', 'ᛁ', 'ᛃ', 'ᛇ', 'ᛈ', 'ᛉ', 'ᛊ', 'ᛏ', 'ᛒ', 'ᛖ', 'ᛗ', 'ᛚ', 'ᛜ', 'ᛞ', 'ᛟ'];

  return (
    <div className="login-page-container">
      {/* Animated background */}
      {useThreeJs ? (
        <LoginBackground />
      ) : (
        <div className="fallback-bg">
          {fallbackRunes.map((rune, index) => (
            <div 
              key={index}
              className="floating-rune"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
                animationDuration: `${15 + Math.random() * 10}s`
              }}
            >
              {rune}
            </div>
          ))}
        </div>
      )}
      
      {/* Login/Signup box */}
      <div className="login-box">
        {/* Decorative rune symbols */}
        <span className="rune-symbol top-left">{runeSymbols.topLeft}</span>
        <span className="rune-symbol top-right">{runeSymbols.topRight}</span>
        <span className="rune-symbol bottom-left">{runeSymbols.bottomLeft}</span>
        <span className="rune-symbol bottom-right">{runeSymbols.bottomRight}</span>
        
        <div className="login-title">
          <h1>First Veil</h1>
          <h2>{isSignup ? 'Forge Your Destiny' : 'Enter the Realm'}</h2>
        </div>
        
        {isSignup ? (
          <form className="login-form" onSubmit={handleSignup}>
            <div className="form-group">
              <label htmlFor="displayName">Character Name</label>
              <input
                type="text"
                id="displayName"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
                placeholder="Enter your character name"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="signup-email">Arcane Sigil (Email)</label>
              <input
                type="email"
                id="signup-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your arcane sigil"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="signup-password">Mystical Key (Password)</label>
              <input
                type="password"
                id="signup-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Create your mystical key"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Mystical Key</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm your mystical key"
              />
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <button
              type="submit"
              className="login-button"
              disabled={loading}
            >
              {loading ? 'Forging Destiny...' : 'Begin Your Journey'}
            </button>
          </form>
        ) : (
          <form className="login-form" onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Arcane Sigil (Email)</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your arcane sigil"
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="password">Mystical Key (Password)</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your mystical key"
              />
            </div>
            
            {error && <div className="error-message">{error}</div>}
            
            <button
              type="submit"
              className="login-button"
              disabled={loading}
            >
              {loading ? 'Casting Spell...' : 'Begin Journey'}
            </button>
          </form>
        )}
        
        <div className="signup-section">
          {isSignup ? (
            <>
              <span className="signup-text">Already have an account?</span>
              <button 
                className="signup-link"
                onClick={toggleForm}
              >
                Return to Portal
              </button>
            </>
          ) : (
            <>
              <span className="signup-text">New to the realm?</span>
              <button 
                className="signup-link"
                onClick={toggleForm}
              >
                Forge Your Destiny
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage; 